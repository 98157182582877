import { createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "layout-wrapper layout-static layout-static-active layout-menu-light layout-topbar-light"
};
import { ref, onMounted, onUnmounted } from "vue";
import AppMenu from "@/components/AppMenu.vue";
import AppTopbar from "@/components/AppTopbar.vue";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var sidebarController = ref(true);
    var isMobileSidebarOpen = ref(false);
    var user = {
      name: "Mathmore",
      image: "https://www.primefaces.org/vue-templates/serenity-react/assets/layout/images/avatar.png"
    };
    function applyResponsiveStyles() {
      var main = document.getElementById("main-trustech");
      var footer = document.getElementById("footerDin");
      var isMobile = window.innerWidth <= 768;
      //quando estiver no mobile
      if (isMobile) {
        if (main) main.style.marginLeft = "1.1rem";
        if (main) main.style.marginTop = "5rem";
        if (footer) footer.style.marginLeft = "0";
      } else {
        if (main) main.style.marginLeft = sidebarController.value ? "21rem" : "6rem";
        if (main) main.style.marginTop = "4.6rem";
        if (footer) footer.style.marginLeft = sidebarController.value ? "17rem" : "0";
      }
    }
    function checkInitialSidebarState() {
      var screenWidth = window.innerWidth;
      // Se a largura da tela for 1366px ou menor, colapsar a sidebar
      if (screenWidth <= 1366) {
        sidebarController.value = false;
      }
    }
    onMounted(function () {
      checkInitialSidebarState();
      document.getElementById("main-trustech").style.marginTop = "4.6rem";
      applyResponsiveStyles();
      window.addEventListener("resize", applyResponsiveStyles);
    });
    onUnmounted(function () {
      document.getElementById("main-trustech").style.marginTop = "0";
      window.removeEventListener("resize", applyResponsiveStyles);
    });
    function toggleSidebar() {
      if (window.innerWidth <= 768) {
        isMobileSidebarOpen.value = !isMobileSidebarOpen.value;
      } else {
        sidebarController.value = !sidebarController.value;
        applyResponsiveStyles();
      }
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(AppTopbar, {
        user: user,
        toggleSidebar: toggleSidebar
      }), _withDirectives(_createElementVNode("div", {
        "class": _normalizeClass(['menu-wrapper', {
          open: isMobileSidebarOpen.value,
          collapsed: !sidebarController.value
        }])
      }, [_createVNode(AppMenu, {
        toggleSidebar: toggleSidebar
      })], 2), [[_vShow, sidebarController.value || isMobileSidebarOpen.value]])]);
    };
  }
};